<template>
    <v-theme-provider dark>
        <base-section id="contact-us" space="40">
            <v-container>
                <v-row justify="center">
                    <v-col cols="10">
                        <base-title
                            :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
                            title="HAVE QUESTIONS? LET US KNOW!"
                        />

                        <base-body space="0">
                            <span class="white--text"
                                >If you are yet not cleared on our services, you
                                can contact us, our awesome support teams are
                                ready to answer all your questions.</span
                            >
                        </base-body>
                    </v-col>

                    <v-col cols="12" md="2">
                        <base-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            color="white"
                            to="/contact"
                            large
                            outlined
                            >Contact Us</base-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </base-section>
    </v-theme-provider>
</template>

<script>
export default {
    name: 'SectionContactUs',
};
</script>
<style scoped>
#contact-us {
    background-color: #4cc4f4;
}
</style>
